<template>
  <div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      src: "",
      flag: false,
    };
  },
  mounted() {
    window.open("/home.html", "_self");
  },
};
</script>

